/* Happy Cog story */

#story .page {
	padding: 0;
	background-image: none;
}

.story-head {
	background: #FC3 url(../images/bg-page-pattern-bright.png) no-repeat 50% -11px;
	overflow: hidden;

	.wrap {
		position: relative;
		width: 940px;
		margin: 0 auto;
		line-height: 1.4;
	}
	h1 {
		margin: 46px 0 52px 300px;
		color: #202020;
		text-shadow: 0 1px 1px rgba(255, 255, 255, .4);
		font: normal normal 27px $f-accent;
		-webkit-font-smoothing: antialiased;
	}
}
.story-body {
	.wrap {
		line-height: 1.6;
		font-size: 1.1em;
	}
	h2 {
		margin: 2em 0 .75em;
		font-size: 1.3em;
	}
	p {
		text-align: justify;
	}
	figure {
		float: left;
		margin-left: -300px;
	}
	figure img {
		display: block;
		width: 210px;
		margin: .5em 0 40px;
		border: 1px solid #DDD;
		padding: 4px;
	}
	p.author {
		margin-top: 2.5em;
		line-height: 1.5;
		color: #999;
	}
	p.author img {
		float: left;
		margin-top: -.3em;
		margin-right: 1em;
	}
	p.author strong {
		display: block;
		color: #000;
	}
}
