@use 'sass:math';

/* Forms
============================== */

%warning-icon {
	content: '';
	display: inline-block;
	width: 15px;
	height: 13px;
	margin-right: .4em;
	background: url('../images/i-warning.svg') no-repeat;
	background-size: 15px 12px;
	vertical-align: -.1em;
}

.signup-container {
	width: 85%;
	max-width: 850px;
	min-width: 720px;
	margin: 0 auto 40px;
	box-sizing: border-box;
	border-radius: 5px;
	border: 1px solid #B6D1AF;
	padding: 30px;
	background-color: #FFF;

	fieldset {
		width: 500px;
		margin: 0 auto;
		text-align: left;
	}
}
	.form-title {
		margin: 0;
		color: #064;
		font: normal normal 1.5em $f-accent;
	}
	.form-sub-title {
		color: #7BB7A3;
		font-size: .85em;
		-webkit-font-smoothing: antialiased;

		.sep {
			margin: 0 .25em;
			color: inherit;
		}
	}
	.form-information {
		float: right;
		margin: .6em 0 0;
		color: #5E5E5E;
		text-align: right;
		font: normal normal .7em $f-sansserif;
	}

// Expand form

.signup-container {
	opacity: .66;

	&.is-expanded {
		opacity: 1;
	}
	&.is-expanded .form-content {
		margin-top: 30px;
		border-top: 1px solid #E4E4E4;
		padding-top: 30px;
	}
}


fieldset legend {
	color: #000;
	font-weight: bold;
	font-size: 1.6em;
}

.spacer {
	border-top: 1px solid #E4E4E4;
	width: 100%;
	margin: 30px auto;
}

.detached {
	margin-top: 2.4em !important;
	margin-bottom: 2.4em !important;

	&:last-of-type {
		margin-bottom: 0 !important;
	}
}

.no-top-margin {
	margin-top: 0 !important;
}

.form-row {
	margin: 1em 0 1.25em;
	overflow: hidden;
}
	.form-label {
		float: left;
		width: 140px;
		padding-top: .55em;
		font-size: 1.2em;
		text-align: right;
	}
	.form-field,
	.form-comment,
	.form-text {
		margin-left: 160px;

		// Prevent Chrome from cutting fields:
		padding-top: .05em; 
		padding-bottom: .05em;
	}
	.form-field {

		&.enlarge {
			font-size: 1.2em;
		}
		&.url {
			color: #888;
		}
	}
	.form-break {
		border-bottom: 1px solid #E4E4E4;
		padding-bottom: 30px;
	}
	.extra-margin {
		margin-bottom: 30px !important;
	}
	.form-comment,
	.form-comment-inline,
	.form-text {
		color: rgba(#000, .4);
		font-size: .9em;
	}
		.form-comment p {
			margin: .5em 0 0;
			padding: 0 !important;
		}
		.form-comment p img {
			vertical-align: -.8em;
		}
	.form-comment-inline {
		display: inline-block;
		margin-left: 20px;
	}


	/* Form elements styling */

	.form-field select {
		margin-top: .5em;
		margin-right: .5em;
		font-size: 1.3em;
	}
	.form-field textarea {
		width: 440px;
		padding: .6em 9px;
	}
	.form-field textarea,
	.form-field input {
		font-size: 1.2em;

		&.fullsize {
			width: 100%;
		}
	}
	.form-field input[type='text'],
	.form-field input[type='password'],
	.form-field input[type='email'],
	.form-field input[type='url'] {
		box-sizing: border-box;
		margin: 0;
		border: 1px solid #CCC;
		border-top-color: darken(#CCC, 12.75%);
		padding: .6em 30px .6em 9px;
		background-color: #FFF;
		outline: none;
		box-shadow: inset 0 1px 0 rgba(0, 0, 0, .1);

		&:focus {
			border-color: $c-form-focus-border;
			border-top-color: darken($c-form-focus-border, 12.75%) !important;
			background-color: $c-form-focus-bg;
		}

		&.halfsize {
			width: 228px;
		}
		&.threequartersize {
			width: 345px;
		}

	}
	.form-field .spinner-on-field {
		position: relative;
		float: right;
		margin-top: -1.5em;
		margin-right: 1.5em;
	}
	.form-field span input[type='password'],
	.form-field span input[type='email'] {
		padding-right: 25px;
		padding-left: 25px;
	}


	/* Checkbox with multiline label */

	.multiline-checkbox {
		display: block;
		padding-left: 1.7em;

		input {
			margin-left: -1.2em !important;
		}
	}


	/* Icons */
	
	.form-field span {
		position: relative;
		color: #1B1B1B;

		i {
			position: absolute;
			top: 50%;
			left: 10px;
			width: 11px;
			height: 11px;
			margin: -6px 0 0;
			background-position: center center;
			background-repeat: no-repeat;

			&.email {
				background-image: url(../images/i-input-email.png);
			}
			&.password {
				background-image: url(../images/i-input-password.png);
			}
		}
	}


	/* Credit card type */

	$cc-width:  32px;
	$cc-height: 21px;
	$cc-margin: 5px;

	.cc-num-container {
		margin-right: (($cc-width + $cc-margin) * 3) + $cc-margin;
	}
	.cc-type {
		float: right;
		width: ($cc-width + $cc-margin) * 3;
		margin: .6em 0;
		overflow: hidden;

		li {
			float: left;
			width:  $cc-width;
			height: $cc-height;
			margin: 0 0 0 $cc-margin;
			background-image: url(../images/i-cc.png);
			background-repeat: no-repeat;
			background-size: #{$cc-width * 2} #{$cc-height * 3};
			list-style: none;
			text-indent: -999em;
			overflow: hidden;

			&.cc-visa               { background-position: -$cc-width 0; }
			&.cc-mastercard         { background-position: -$cc-width #{-$cc-height * 1}; }
			&.cc-amex               { background-position: -$cc-width #{-$cc-height * 2}; }

			&.current.cc-visa       { background-position: 0 0; }
			&.current.cc-mastercard { background-position: 0 #{-$cc-height * 1}; }
			&.current.cc-amex       { background-position: 0 #{-$cc-height * 2}; }
		}
	}


	@include retina {
		$cc-width-retina:  30px;
		$cc-height-retina: 19px;

		.cc-num-container {
			margin-right: (($cc-width-retina + $cc-margin) * 3) + $cc-margin;
		}
		.cc-type {
			width: ($cc-width-retina + $cc-margin) * 3;

			li {
				width:  $cc-width-retina;
				height: $cc-height-retina;
				background-image: url(../images/i-cc@2x.png);
				background-size: #{$cc-width-retina * 2} #{$cc-height-retina * 3};

				&.cc-visa               { background-position: -$cc-width-retina 0; }
				&.cc-mastercard         { background-position: -$cc-width-retina #{-$cc-height-retina * 1}; }
				&.cc-amex               { background-position: -$cc-width-retina #{-$cc-height-retina * 2}; }

				&.current.cc-visa       { background-position: 0 0; }
				&.current.cc-mastercard { background-position: 0 #{-$cc-height-retina * 1}; }
				&.current.cc-amex       { background-position: 0 #{-$cc-height-retina * 2}; }
			}
		}
	}
	ul.cc-selector li { 
		float: left; 
		margin: 0 1.25em 0 0; 
		list-style: none; 
	} 
		ul.cc-selector li input { 
			float: left; 
			margin-right: 0; 
		} 
		ul.cc-selector li label { 
			float: left; 
			width: 40px; 
			height: 30px; 
			background: url(../images/i-cc.png) no-repeat center 0; 
			text-indent: -9999px; 
			overflow: hidden; 
		} 
		ul.cc-selector li.cc-visa label       { background-position: center 0; } 
		ul.cc-selector li.cc-mastercard label { background-position: center -100px; } 
		ul.cc-selector li.cc-amex label       { background-position: center -200px; } 


	/* Password Strength */

	.password-strength,
	.password-confirm {
		margin: 1em 0 !important;
		color: rgba(#000, .4) !important;
		line-height: 1.5;
	}
		.password-strength {
			&:before {
				// Reset warning icon set by .error-msg:before
				content: none !important;
			}
		}

		.psw-label {
			display: inline;
			font-weight: normal;
		}
		.psw-label-weak {
			color: $c-bad;

			&:before {
				@extend %warning-icon;
			}
		}
		.psw-label-weak.psw-label-recommendation {
			color: $c-orange;
		}
		.psw-label-strong {
			color: $c-good;
		}


	/* Errors handling */

	.form-row.form-error {
		margin-right: -1em;
		margin-left:  -1em;
		border-radius: 3px;
		padding: 1em;
		background-color: darken($c-form-error-bg, 2%);

		.form-label {
			color: $c-form-error;
		}
	}

		// New universal validation classes

		.validation {
			margin: .75em 0 0 160px;

			a {
				color: inherit;
			}

			.form-text & {
				margin-left: 0;
			}

			.spinner {
				display: inline-block !important;
				left: .75em !important;
			}
		}
		.validation--error {
			color: $c-bad;

			&:before {
				@extend %warning-icon;
			}
		}
		.validation--ok {
			color: $c-good;
		}


		// Old class required for compatibility

		.error-msg {
			@extend .validation;
			@extend .validation--error;
			margin-left: 0 !important;
			padding-top: .33em;
			font-size: .9em;
			margin-right: math.div($central-field, math.div((100% - $central-field), 2) * -100);
		}


	/* Error message */

	#minimal .notification {
		margin: -30px -40px 30px;
		border-width: 0 0 2px;
		border-style: solid;
		border-radius: 5px 5px 0 0;
		padding: 30px 40px;
		font-size: 1.2em;

		h2,
		p {
			margin: 0 0 .2em;
		}

		&.error {
			border-color: $c-form-error;
			background-color: $c-form-error-bg;
			color: #333;

			h2 {
				color: $c-form-error;
			}
		}

		&.success {
			margin-bottom: -2em;
			border: 0;
			border-radius: 5px;
			background-color: #EFEFFF;
			color: #334;

			h2 {
				color: #000;
			}
		}
	}

	.spring .notification {
		min-width: 790px;
		margin: 0;
		border-bottom: 1px solid mix(#FFF, $c-spring-bg, 75%);
		padding: 1.4em 15% 1.5em;
		background-repeat: repeat-x;
		background-position: 0 100%;
		background-size: .75em .75em;
		text-align: center;
		font-size: .85em;

		h2,
		p {
			margin: 0;
		}
		h2 {
			margin-bottom: .2em;
			font-size: 1.1em;
		}
		a {
			color: inherit;
		}
		h2,
		strong,
		a:hover {
			color: #FFF;
		}

		&.error {
			background-color: $c-form-error-dark;
			background-image: linear-gradient(to bottom, $c-form-error-dark, darken($c-form-error-dark, 3%));
			color: mix(#FFF, $c-form-error-dark, 80%);
		}

		&.success {
			background-color: $c-green-darkest;
			background-image: linear-gradient(to bottom, $c-green-darkest, darken($c-green-darkest, 3%));
			color: mix(#FFF, $c-green-darkest, 80%);
		}

		&.referral {
			background-color: $c-brown-dark;
			background-image: linear-gradient(to bottom, $c-brown-dark, darken($c-brown-dark, 3%));
			color: mix(#FFF, $c-brown-dark, 80%);
		}
	}

	/* In-form validation */

	.field-ok {
		background: url('../images/i-confirm.svg') no-repeat 97.5% 50%;
		background-size: 14px 14px;
	}

	.field-error,
	.form-error input[type='text'],
	.form-error input[type='password'],
	.form-error input[type='email'],
	.form-error input[type='url'] {
		background-color: #FFF8F4 !important;

		&:focus {
			border-color: #CCA199 !important;
			border-top-color: darken(#CCA199, 12.75%) !important;
		}
	}


	/* URLs validation */

	.url-preview {
		position: relative;
		height: 3em;
		margin: 5px 0 1px;
		border: 1px solid #DDD;
		border-radius: 3px;
		color: #888;
		font: .95em/1.5em $f-monospace;
		white-space: nowrap;
		overflow: hidden;
	}
	.url-preview--plate {
		border: 1px solid #B6D1AF;
		background-color: #E9F4E4 !important;
	}
	.url-preview-wrap {
		box-sizing: border-box;
		position: absolute;
		right: 0;
		min-width: 100%;
		padding: .8em .75em .75em;
	}
	.url-input {
		margin: 0 .3em;
		border-radius: 1px;
		padding: .1em .4em;
		background-color: #FFE586;
		color: #000;
		font-weight: normal;
	}
	.form-error .url-preview {
		border: 1px dashed #CCCA9C;
		background-color: #FFFEDD;
		color: #999884;
		cursor: pointer;
		box-shadow: none;
	}



/* Charge summary */

.billing-summary {
	box-sizing: border-box;
	width: 100%;
	background-color: #FEFFEE;
	border-top: 1px solid #E4E4E4;
	padding: 30px;
	margin: 0 auto;
	line-height: 1.6;
	text-align: center;

	p.billing-summary-product {
		font-weight: bold;
		color: #6D552A;
		font-size: 2em;
		margin: 0 0 30px;
		border-bottom: 1px solid #E6E7D1;
		padding: 0 0 30px;
		overflow: auto;

		.product {
			float: left;
			display: block;
		}
		.price {
			float: right;
			display: block;
		}
		.description {
			float: left;
			display: block;
			font-weight: normal;
			color: #AF8637;
			font-size: .7em;
			clear: both;
		}
	}

	p.billing-summary-total {
		font-weight: bold;
		color: #000;
		font-size: 1.4em;

		.label {
			float: left;
			display: block;
		}

		.price {
			float: right;
			display: block;
		}
	}
}


/* Charge notice */

.billing-message {
	box-sizing: border-box;
	width: 100%; // WTF?
	margin: 0 auto;
	border: 1px solid #B6D1AF;
	padding: 30px 40px;
	color: #474747;
	line-height: 1.6;
	text-align: center;

	.billing-message-title{
		font: normal normal 1.1em $f-accent;
		color: #064;
		line-height: 1.9;
		display: block;
	}
}
