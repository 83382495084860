/* API
============================== */

sup.footnote {
	position: relative;
	top: -.5em;
	font-size: .85em;
	line-height: 0;
	vertical-align: baseline;
}
p.footnote {
	margin-top: 2.5em !important;
	padding-left: 1.5em;
	color: #666;
	font-size: .9em;
}
	p.footnote sup {
		float: left;
		margin-left: -1.5em;
	}

#api {
	overflow: hidden;

	#doc {
		float: left;
		width: 620px;

		h1 {
			margin: 1.25em 0 .75em;
			color: #046;
			font-size: 1.6em;
		}
		h2 {
			margin: 2em 0 1.25em;
			font-size: 1.3em;
			color: #000;
		}
		h3 {
			margin: 2em 0 1.25em;
			color: #046;
			font-size: 1.2em;
		}
		p {
			margin: 0 0 1em;
			line-height: 1.6;
		}
		p.warning {
			margin: 2em 0;
			border-left: 2px solid #D88;
			padding: .2em 0 .2em 1em;
			color: #B44;
			line-height: 1.4;
			overflow: hidden;
		}
		ul,
		ol {
			margin: 0 0 1em 3em;

			li {
				list-style-position: outside;				
			}
		}
			ul li {
				list-style-type: circle;
			}
	}
}
		table.properties {
			margin: 1.5em -1em 2em;
			border-top: 1px solid #EEE;
			line-height: 1.5;
			width: 100%;

			th,
			td {
				border-bottom: 1px solid #EEE;
				border-collapse: collapse;
				padding: .75em 1.5em .75em 0;
				text-align: left;

				&:first-child {
					padding-left: 1em;
				}
				&:last-child {
					padding-right: 1em;
				}
			}
			th {
				width: 33%;
				white-space: nowrap;
				font: normal .85em/1.5 $f-monospace;
			}

			&.with-type {
				td:first-of-type {
					width: 12%;
					font-style: italic;
				}

				tr.subprop th {
					padding-left: 3.5em;
					font-size: .75em;
				}
			}
		}



	/* Code snippets */

	#api .code_container {
		position: relative;
		display: block;
		margin: 2em -1em;
		border-width: 1px 0;
		border-style: solid;
		border-color: #DDD;
		padding: 1.5em 1em 1.7em;
		background-color: #F5F5F5;

		i {
			position: absolute;
			top: -.8em;
			right: 1.1764em;
			border-radius: 2px;
			padding: .1em .5em;
			background-color: #DDD;
			color: #333;
			text-transform: uppercase;
			text-shadow: 0 1px 0 rgba(255, 255, 255, .75);
			font-style: normal;
			font-weight: bold;
			font-size: .85em;
			line-height: 1.3;
		}
		p {
			margin: 0 !important;
			padding: 0;
			color: #999;
			font-weight: bold;
			font-size: .9em;
			text-decoration: none;
			text-shadow: 0 1px 0 #FFF;
		}

		/* HTTP Request */

		&.http-request {
			border-color: #D5E1EC;
			background-color: #F9F9FF;

			i {
				background-color: #D5E1EC;
				color: $c-link;
			}
		}

		/* Ruby */

		&.ruby {
			border-color: #D0EFC8;
			background-color: #F3FFF0;

			i {
				background-color: #D0EFC8;
				color: #166D00;
			}
		}

		/* Response */

		&.response {
			border-color: #DDD;
			background-color: #F5F5F5;
			cursor: pointer;

			i {
				background-color: #DDD;
				color: #333;
			}
			pre,
			&.expand p {
				display: none;
			}
			&.expand {
				cursor: default;
			}
			&.expand pre {
				display: block;
			}
		}
	}


/* Tabs */

#api #sidebar {
	position: fixed;
	top: 160px;
	left: 50%;
	margin-left: 250px;

	&.stick {
		position: absolute;
	}
	p.meta {
		margin: .6em 0;
		color: #AAA;
		text-align: center;
		font-size: .85em;
	}
	ul {
		margin: .5em 0 0;
		padding: 1em;
		border-radius: 5px;
		background: #F3F3F3;
		background: rgba(0, 0, 0, .05);

		li {
			margin: 0;
			padding: .35em .5em;
			list-style: none;

			a,
			strong {
				display: block;
				color: #888;
				text-decoration: none;
			}
			a:hover {
				color: #333;
			}
			strong {
				color: #000;
			}
		}
	}

	.badge {
		margin-left: .33em;
		border-radius: 2px;
		padding: 0 .4em .1em;
		background-color: #549E5E;
		color: #FFF;
		font-weight: bold;
		font-size: .9em;
		line-height: 1;
		text-shadow: 0 1px 1px rgba(0, 0, 0, .25);

		a:hover & {
			background-color: #40804A;
		}
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px), only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	#api #sidebar {
		position: absolute;
	}
}


/* Output Switcher */

.output-switcher {
	$experimental-support-for-svg: true;

	position: relative;
	width: 184px;
	height: 35px;
	margin: 2em 1.5em 0;
	border-radius: 5px;
	background-color: #DADADA;
	background-image: linear-gradient(to bottom, #DADADA, #F4F4F4);
	box-shadow: inset 0 1px 3px rgba(#000, .25);
	overflow: hidden;
}
	.output-type {
		box-sizing: border-box;
		position: relative;
		float: left;
		width: 86px;
		height: 29px;
		margin: 3px;
		border-radius: 3px;
		padding-top: 5px;
		color: #777;
		text-shadow: 0 1px 1px #FFF;
		text-align: center;
		text-decoration: none;
		font-weight: bold;
		outline: none;

		&.selected {
			$experimental-support-for-svg: true;

			background-color: #86CB7A;
			background-image: url('../images/bg-output-type.png'), linear-gradient(to bottom, #86CB7A, #56AA63);
			background-size: 4px 4px, auto auto;
			color: #FFF !important;
			text-shadow: 0 1px 1px rgba(0, 0, 0, .33);
			box-shadow: 0 0 1px rgba(#000, .20), inset 0 0 2px rgba(#227330, .75);
			cursor: default;

			&:before {
				content: '';
				position: absolute;
				top: 1px;
				right: 1px;
				bottom: 1px;
				left: 1px;
				border-radius: 2px;
				box-shadow: inset 0 1px 1px rgba(#FFF, .5);
			}
		}
	}
	a.output-type {
		&:link,
		&:visited {
			color: #777;
		}
		&:hover {
			color: #333;
			transition: color .1s linear;
		}
	}

.output-switcher--disabled {
	.output-type {
		&.selected {
			background-color: #B5B5B5;
			background-image: none;
			box-shadow: 0 0 1px rgba(#FFF, .75), inset 0 0 2px rgba(#000, .33);

			&:before {
				content: none;
			}
		}
	}
}


/* Syntax Highlighter */

.html .tag        { color: #008000; }
.html .doctype    { color: #708090; }

.erb .tag         { color: #008000; }
.erb .doctype     { color: #708090; }

.ruby .constants  { color: #A0A; }
.ruby .method     { color: #000; }
.ruby .keywords   { color: #F00; }
.ruby .ivar       { color: #00F; }
.ruby .comment    { color: #708090; }
.ruby .symbol     { color: #008000; }
.ruby .string     { color: #008000; }
