/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */

code[class*="language-"],
pre[class*="language-"] {
	color: #F8F8F2;
	text-shadow: 0 1px rgba(0, 0, 0, .3);
	font-family: Consolas, Monaco, 'Andale Mono', monospace;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;

	-moz-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
	border-radius: .3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #272822;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .1em;
	border-radius: .3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #708090;
}

.token.punctuation {
	color: #F8F8F2;
}

.namespace {
	opacity: .7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol {
	color: #F92672;
}

.token.boolean,
.token.number {
	color: #AE81FF;
}

.token.selector,
.token.attr-name,
.token.string,
.token.builtin {
	color: #A6E22E;
}


.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
	color: #F8F8F2;
}

.token.atrule,
.token.attr-value {
	color: #E6DB74;
}


.token.keyword {
	color: #66D9EF;
}

.token.regex,
.token.important {
	color: #FD971F;
}

.token.important {
	font-weight: bold;
}

.token.entity {
	cursor: help;
}

