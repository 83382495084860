/* Based on Eric Meyer's Reset v2.0 20110126 */
/* http://meyerweb.com/eric/tools/css/reset/ */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, hr, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	border: 0;
	padding: 0;
	font-size: 100%;
}
blockquote {
	quotes: none;
}
blockquote:before,
blockquote:after {
	content: '';
	content: none;
}