/* Features
============================== */

#features {
}
	#features .page {
		padding: 0;
		background-image: none;
	}

/* Header */

#feature-head {
	position: relative;
	height: 220px;
	background: #E2F7CD url(../images/bg-feature-head.png) repeat-x 0 0;

	.wrap {
		position: relative;
		height: 220px;
		background: url(../images/bg-feature-head-pattern.png) no-repeat 50% 0;
		overflow: hidden;
	}

	.cl,
	.cr {
		position: absolute;
		bottom: -20px;
		width: 25px;
		height: 23px;
		background: url(../images/bg-feature-corners.png) no-repeat 0 0;
	}
		.cl {
			left: 0;
		}
		.cr {
			right: 0;
			background-position: 100% 0;
		}

	hgroup {
		width: 940px;
		margin: 0 auto;
		padding: 41px 0 0;
		text-align: center;
		line-height: 1.4;

		h1 {
			margin: 0 0 .2em;
			padding: 0;
			color: #064;
			font: normal normal 2.34375em $f-accent; /* 30px / (16px * 0.8em) = 2.34375em */
			text-shadow: 0 1px 1px #FFF;
			-webkit-font-smoothing: antialiased;
		}
		h2 {
			margin: 0;
			padding: 0;
			color: #6A8066;
			font-weight: normal;
			font-size: 1.15em;
		}
	}

	nav {

		ul {
			position: absolute;
			top: 148px; left: 50%;
			width: 980px;
			height: 72px;
			margin: 0 0 0 -490px;
			z-index: 99;
			overflow: hidden;

			li {
				position: relative;
				float: left;
				width: 196px;
				height: 41px;
				margin: 0;
				padding: 31px 0 0;
				background: url(../images/bg-feature-nav.png) no-repeat 0 0;
				background-size: 392px 500px;
				font: normal normal 1.15em/1.3 $f-accent;
				text-align: center;
				text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
				-webkit-font-smoothing: antialiased;

				a {
					display: inline-block;
					border-radius: 3px;
					padding: .1em .5em .3em;
					color: #3B6633 !important;
					text-decoration: underline;
					transition: background .15s ease-in-out;
				}
				a:hover {
					background-color: #E0F7D5;
				}
			}
			li.current {
				a {
					background-color: #147351;
					color: #FFF !important;
					font-weight: normal;
					text-decoration: none;
					text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
				}
				a::after {
					content: '';
					position: absolute;
					bottom: 0; left: 50%;
					width: 20px;
					height: 9px;
					margin-left: -10px;
					background: url(../images/bg-feature-nav-pointer.png) no-repeat 0 0;
				}
			}

			li.n-vcs                   { background-position: 0 0; }
			li.n-deployments           { background-position: 0 -100px; }
			li.n-integration           { background-position: 0 -200px; }
			li.n-collaboration         { background-position: 0 -300px; }
			li.n-security              { background-position: 0 -400px; }

			li.current.n-vcs           { background-position: 100% 0; }
			li.current.n-deployments   { background-position: 100% -100px; }
			li.current.n-integration   { background-position: 100% -200px; }
			li.current.n-collaboration { background-position: 100% -300px; }
			li.current.n-security      { background-position: 100% -400px; }
		}

		.hr {
			position: absolute;
			top: 160px; left: 0;
			width: 100%;
			height: 2px;
			background-color: #668A5F;
			z-index: 98;
		}
	}

	.i-leaf {
		position: absolute;
		top: 0; right: 0;
		width: 161px;
		height: 43px;
		background: url(../images/bg-feature-head-leaf.png) no-repeat 0 0;
		background-size: 100%;
	}
}

/* Footer */

#feature-footer {

	/* Previous / Next */

	nav {
		position: relative;
		height: 3.25em;
		margin: 20px 0 60px;

		a {
			position: absolute;
			margin: 0 10px;
			border-radius: 3px;
			border-width: 2px;
			border-style: solid;
			padding: .6em 1em;
			line-height: 1.2;
			font-size: 1.2em;
			z-index: 99;
		}
			a i {
				position: absolute;
				top: 50%;
				width: 15px;
				height: 24px;
				margin: -12px 0 0;
				background: url(../images/i-feature-nav-corner.png) no-repeat 0 0;
			}

		a.go-prev {
			right: 50%;
			border-color: #D9D9D9;
			background-color: #FFF;
			color: #AAA;
		}
		a.go-prev:hover {
			border-color: #B9B9B9;
			color: #909090;
		}
			a.go-prev i {
				left: -15px;
				background-position: 0 0;
			}
			a.go-prev:hover i {
				background-position: 0 100%;
			}

		a.go-next {
			left: 50%;
			border-color: #66B3D9;
			background-color: $c-blue-light;
			color: #1B80B2;
		}
		a.go-next:hover {
			border-color: #40A0CF;
			background-color: #C7E4F5;
			color: #176E99;
		}
			a.go-next i {
				right: -15px;
				background-position: 100% 0;
			}
			a.go-next:hover i {
				background-position: 100% 100%;
			}

		.hr {
			position: absolute;
			top: 1.5em; left: 0;
			width: 100%;
			height: 2px;
			background: #F2F2F2;
			z-index: 98;
		}
	}

	/* Actions */

	.wrap {
		position: relative;
		width: 940px;
		height: 90px;
		margin: 60px auto;
	}
		h1 {
			margin: 0;
			font-size: 1em;
		}

		a.button-signup {
			position: absolute;
			top: 0; left: 320px;
			width: 300px;
			height: 90px;
			background: url(../images/b-features-signup.png) no-repeat;
			text-decoration: none;
			text-align: center;
			text-shadow: 0 1px 0 rgba(255, 255, 255, .5);

			&:hover {
				background-position: 0 100%;

				h1 {
					color: #053;
				}
			}

			h1 {
				margin: 23px 0 0;
				color: #064;
				font: normal normal 16px/1.3 $f-accent;
				-webkit-font-smoothing: antialiased;
			}
			p {
				color: #548C70;
			}
		}

		.buzz {
			float: right;
			width: 300px;
			padding-top: 25px;

			a {
				color: #999;
			}
			a:hover {
				color: #000;
			}
		}
}


/* Section Title */

#features h1.section-title,
#features hgroup.section-title h1 {
	margin: 2.5em 0;
	color: #427339;
	text-align: center;
	font: normal normal 1.64em $f-accent; /* 21px / (16px * 0.8em) = 1.64em */
	-webkit-font-smoothing: antialiased;
}
#features hgroup.section-title h1 {
	margin-bottom: 0;
}
#features hgroup.section-title h2 {
	margin-bottom: 4.1em;
	color: #7B8C77;
	text-align: center;
	font-weight: normal;
	font-size: 1em;

	a {
		color: inherit;
	}
}

.badge-new {
	margin-right: .3em;
	border-radius: 2px;
	padding: .1em .3em;
	background-color: #E47911;
	color: #FFF;
	text-transform: uppercase;
	font: bold .666em $f-sansserif;
	-webkit-font-smoothing: subpixel-antialiased;
	vertical-align: .2em;
}

p.pop {
	margin: -20px 0 40px;
	padding: 1.25em;
	border-radius: 3px;
	background: #F6FAF5;
	color: #7B8C77;
	text-align: center;

	a {
		color: #7B8C77;
	}
	strong {
		color: #427339;
	}
}


/* Group content: 2 columns */

#features .container .wrap {
	margin: 0 -20px;
	border-radius: 5px;
	padding: 40px 20px;
	background-color: #F6FAF5;

	.group {
		margin-top: 0;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.group {
	margin: 20px -30px 40px;
	overflow: hidden;

	&:last-child {
		margin-bottom: 20px;
	}
	section,
	figure {
		float: left;
		width: 440px;
		margin: 0 30px;
	}

	/* Content blocks */

	section {
		color: #777;
		line-height: 1.6;
		hyphens: auto;

		h1 {
			margin: 0 0 1.2em;
			color: #222;
			line-height: .85;
			font-size: 1.3em;
		}
		hgroup h1,
		hgroup h2 {
			margin: 0;
			font-size: 1.3em;
		}
		hgroup h2 {
			margin-bottom: .45em;
			color: #777;
			line-height: 2.2;
			font-weight: normal;
		}
		p {
			margin-bottom: 1.45em;
		}
	}
	
	.feature-no-title {
		margin-top: 4.5em;
	}
		
	
	/* Screenshots */

	.screenshots {
		clear: both;
		margin: 0 0 40px;
		overflow: hidden;

		figure {
		}
			a {
				position: relative;
				display: block;
				height: 158px;
				border: 2px solid #DAE6DA;
				padding: 4px;
				color: #777;
				overflow: hidden;

				img {
					display: block;
					width: 100%;
					height: 100%;
					background-color: #F9F9F9;
				}
				i.i-zoom {
					position: absolute;
					right: 0;
					bottom: 0;
					width: 34px;
					height: 34px;
					background: url(../images/i-zoom.png) no-repeat 0 0;
				  	background-size: 68px 34px;
				}
			}
			a:hover {
				border-color: #C3D9C3;

				i.i-zoom {
					background-position: 100% 0;
				}
			}

			iframe {
				display: block;
				border: 2px solid #DAE6DA;
				padding: 4px;
			}

			figcaption {
				margin: .75em 0 0;
				color: #98A695;
				text-align: center;
				font-size: .9em;
			}
	}
}

/* Wide content */

.group.group--wide {
	margin-right: 0 !important;
	margin-left:  0 !important;

	section,
	figure  {
		float: none;
		width: 600px;
		margin: 0 auto;
	  	text-align: left;
	}
	section h1 {
	  margin-bottom: 1.2em;
	  font-size: 1.3em;
	}
	section h2 {
	  line-height: 1.5;
	}
}

/* Group content: 3 columns */

.group.columns-3 {
	margin-right: -10px !important;
	margin-left:  -10px !important;

	section,
	figure  {
		width: 300px;
		margin: 0 10px;
	}
		.screenshots {
		  margin: 0;
		}
		.screenshots.bottom {
		  margin: 0 0 40px;
		}
  		.screenshots a {
			height: 130px;
			padding: 3px;
		}
		section h1 {
			margin-bottom: .4em;
			font-size: 1.3em;
		}
		section h2 {
		  margin-bottom: .9em;
		  line-height: 1.3;
		}
}


/* Group content: 4 columns */

.group.columns-4 {
	margin-right: -10px;
	margin-left:  -10px;

	section,
	figure {
		width: 220px;
		margin: 0 10px;
	}
		section h1 {
			margin-bottom: .85em;
			font-size: 1.2em;
		}
}


/* Group content: 5 columns */

.group.columns-5 {
	margin-right: -10px;
	margin-left:  -10px;

	section,
	figure {
		width: 168px;
		margin: 0 12px;
	}
		section h1 {
			margin-bottom: .85em;
			font-size: 1.2em;
		}
}


/* Deployment Process */

#deploy-process {
	margin-bottom: 60px;

	h1.section-title {
		color: #4C4C66;
	}

	figure.illustration {
		img {
			display: block;
			width: 770px;
			height: 51px;
			margin: -20px auto 35px;
		}
	}

	.group.columns-4 section {
		color: #7E7E8C;
		line-height: 1.7;
		font-size: .9em;

		h1 {
			color: #334;
		}
		h1 b {
			display: inline-block;
			margin-right: .2em;
			border-radius: 2px;
			padding: .2em .45em .4em;
			background-color: #A3A3BF;
			color: #FFF;
			font-size: .9em;
			vertical-align: .1em;
		}
	}
}


/* Deployment Services */

.deploy-to-services {
	margin: 60px 0;
	text-align: right;
	white-space: nowrap;

	li {
		display: inline-block;
		margin: 0 0 0 47px;
		list-style: none;
		vertical-align: top;
	}
	li.deploy-to-title {
		float: left;
		margin: .5em 0 0;
		color: #334;
		font-weight: bold;
		font-size: 1.1em;

		+ li {
			margin-left: 0;
		}
	}
}
	.label-ftp,
	.label-sftp,
	.label-ssh {
		display: inline-block;
		margin-top: .25em;
		border-radius: 3px;
		padding: .4em 1em;
		font-weight: bold;
		text-shadow: 0 1px 1px rgba(#FFF, .5);
	}
	.label-ftp {
		background-color: #D9D9D9;
		color: #000;
	}
	.label-sftp {
		background-color: #C2CEF2;
		color: #000;
	}
	.label-ssh {
		background-color: #333;
		color: #FFF;
		text-shadow: 0 1px 1px #000;
	}

	.logo-amazon-s3,
	.logo-cloudfiles,
	.logo-heroku,
	.logo-dreamobjects {
		display: inline-block;
		height: 29px;
		background: url(../images/logos-deploy.png) no-repeat;
		background-size: 360px 29px;
		text-align: left;
		text-indent: -999em;
		overflow: hidden;

		@include retina {
			background-image: url(../images/logos-deploy@2x.png);
			background-size: 360px 29px;
		}
	}
	.logo-amazon-s3 {
		width: 79px;
		background-position: 0 0;
	}
	.logo-cloudfiles {
		width: 107px;
		background-position: -79px 0;
	}
	.logo-heroku {
		width: 72px;
		background-position: -186px 0;
	}
	.logo-dreamobjects {
		width: 102px;
		background-position: -258px 0;
	}



/* Deployment to AWS */

#deploy-aws {
	position: relative;
	margin-bottom: 60px;

	h1.section-title {
		margin: 0 0 30px;
		color: #E47911;
	}
	.wrap {
		position: relative;
		padding-bottom: 20px !important;
		background-color: #F5F5F5 !important;
	}
		.wrap .group {
			margin-bottom: 0 !important;
		}
		.i-aws {
			position: absolute;
			top: -13px;
			right: -30px;
			width: 75px;
			height: 55px;
			background: url(../images/bg-aws.png) no-repeat;
		}
}


/* Deployment Best Practices */

#deploy-best-practices {
	position: relative;
	margin-bottom: 60px; 

	h1.section-title {
		margin: 0 0 30px;
	}

	#features & .wrap {
		border-radius: 0 0 20px 20px;
		padding-top: 65px;
		padding-bottom: 20px;
		background: #F6FAF5 url(../images/bg-features-bp.png) no-repeat;
	}

	i.i-leaf {
		position: absolute;
		bottom: 25%;
		left: -68px;
	}
}


/* HappyCog Story */
 
.logo-happycog {
	position: absolute;
	top: 50%;
	left: 51px;
	width: 118px;
	height: 119px;
	margin-top: -58px;
	background: url(../images/happycog-logo.png) no-repeat;
	text-indent: -9999em;
	overflow: hidden;
}

.story-happycog  {
	height: 195px;
	margin: 0 -20px 60px;
	border-radius: 5px;
	background: #FC3 url(../images/happycog-shadow.png) repeat-x;

	.story-pattern {
		height: 100%;
		border-radius: 5px;
		background: url(../images/bg-page-pattern-bright.png) no-repeat 50% -145px;
	}
	.story-wrap {
		position: relative;
		width: 540px;
		height: 195px;
		margin: 0 auto;
		padding-left: 240px;
		color: #333;
		font-size: 1.1em;
		line-height: 1.5;
		overflow: hidden;
	}
		h1 {
			margin: 1.85em 0 .4em;
			color: #202020;
			font-size: 1.25em;
		}
		p {
			margin: .2em 0 1.1em;
		}
		a.button {
			display: inline-block;
			border-radius: 4px;
			padding: .5em 1em .6em;
			background: #222;
			color: #FFF;
			text-decoration: none;
			text-shadow: 0 1px 1px #000;
			line-height: 1.3;
			font-weight: bold;
			font-size: 1em;
			box-shadow: 0 0 2px rgba(255, 255, 255, .5);
		}
		a.button:hover {
			background-color: #000;
		}
}



/* Integrations */

.int-type {
	position: relative;

	header {
		margin: 60px 160px 60px 80px;
		padding-left: 80px;
		background: url(../images/feature-int.png) no-repeat;
		color: #777;
		line-height: 1.6;

		h1 {
			font-size: 1.4em;
		}
	}
	&.int-issues  header { background-position: 5px 8px; }
	&.int-time    header { background-position: 5px -192px; }
	&.int-discuss header { background-position: 5px -392px; }


	#features & .wrap {
		padding-top: 75px;
	}
		.group {
			overflow: visible;
		}
			.group .clear {
				clear: both;
			}
			.group section {
				position: relative;
			}
				.wrap .group section h1 {
					margin-bottom: .85em;
					font-size: 1.4em;
				}
				.wrap .group section p {
					position: relative;
					margin: 0 !important;
				}
				.wrap .group section a {
					color: #444;
					font-weight: bold;
				}

				.int-logo {
					position: absolute;
					top: -135px; /* 60px + .wrap padding-top */
					left: 50%;
					height: 140px;
					background: url(../images/int-logos.png) no-repeat;
				}
				.int-logo.logo-fogbugz    { width: 79px;   margin-left: -40px;  background-position:   -10px 0; }
				.int-logo.logo-lighthouse { width: 77px;   margin-left: -38px;  background-position:  -109px 0; }
				.int-logo.logo-zendesk    { width: 117px;  margin-left: -59px;  background-position:  -206px 0; }
				.int-logo.logo-sifter     { width: 124px;  margin-left: -62px;  background-position:  -343px 0; }
				.int-logo.logo-basecamp   { width:  91px;  margin-left: -46px;  background-position:  -487px 0; }
				.int-logo.logo-freckle    { width:  83px;  margin-left: -42px;  background-position:  -598px 0; }
				.int-logo.logo-harvest    { width: 112px;  margin-left: -56px;  background-position:  -701px 0; }
				.int-logo.logo-campfire   { width:  91px;  margin-left: -46px;  background-position:  -833px 0; }
				.int-logo.logo-hipchat    { width:  90px;  margin-left: -45px;  background-position:  -944px 0; }
				.int-logo.logo-webhooks   { width:  56px;  margin-left: -28px;  background-position: -1058px 0; }
				.int-logo.logo-api        { width:  53px;  margin-left: -26px;  background-position: -1134px 0; }
				.int-logo.logo-jira       { width:  109px; margin-left: -62px;  background-position: -1208px 0; }

	&.int-time {

		i.i-leaf {
			position: absolute;
			bottom: 10%; right: -52px;
			width: 32px;
			height: 94px;
			background: url(../images/leaf-rs.png) no-repeat;
			background-size: 100%;
		}
	}

	&.int-build {
		margin-bottom: 60px;

		header {
			margin: 60px 160px 0;
			padding: 0 0 49px;
			background: url(../images/bg-feature-int-build.png) no-repeat 50% 100%;
		}
			header h1.section-title {
				margin: 0 !important;
				color: #4C4C66 !important;
			}
			header h1.section-title span {
				padding: 0 .4em;
				background: #FFF;
				font-weight: normal;
			}

		#features & .wrap {
			padding-top: 40px;
			background-color: #F8F8FA;
		}
			section {
				width: 360px;
				padding-left: 80px;
				color: #7E7E8C;
			}
			.wrap .group section h1,
			.wrap .group section a {
				color: #4C4C66;
			}
			section .int-logo {
				top: -58px;
				left: 0;
				right: 60px;
				margin-left: 0;
			}

		p.link-more {
			margin: 0;
			text-align: center;

			a {
				display: inline-block;
				border-radius: 0 0 3px 3px;
				border-top: 1px solid #ADADC5;
				padding: .3em .8em .5em;
				background: #BABACF;
				color: #FFF;
				text-decoration: none;
				text-shadow: 0 1px 1px rgba(0, 0, 0, .25);
				font-weight: bold;
			}
			a:hover {
				background-color: #ADADC5;
			}
		}
	}
}



/* Security */

#features #business-only {
	margin: -10px 0 40px;

	h1.section-title {
		position: absolute;
		top: -34px; right: -10px;
		width: 93px;
		height: 93px;
		margin: 0;
		padding: 0;
		background: url(../images/stamp-business.png) no-repeat;
	  	background-size: 93px 93px;
		text-indent: -9999em;
		overflow: hidden;
	}
	.wrap {
		background-color: #F8F8FA;
	}
		.group {
			margin-bottom: 0;
		}
		.group section {
			color: #7E7E8C;
		}
		.group section h1,
		.group section a {
			color: #4C4C66;
		}
		.group section p:last-child {
			margin-bottom: 0;
		}
}
