/* Blog
============================== */

#tumblr_controls {
	display: none;
}

#blog {
	overflow: hidden;
}
#articles {
	float: left;
	padding: 25px 0 0 140px;
}


	/* Navigation */

	#articles > nav {
		margin: 2em 0;
		border-top: 1px solid #DDD;
		padding-top: 1em;
		font-size: 1.1em;
		overflow: hidden;

		a {
			margin-right: 1em;
			border-bottom: 1px solid #DDD;
			color: #99A;
			text-decoration: none;
		}
		a:hover {
			border: 0;
		}
	}


	/* Entry */

	article {
		position: relative;
		width: 500px;
		margin: 0 0 40px;

		h2 {
			margin: 0 0 .3em;
			color: #046;
			font-size: 1.6em;

			a {
				border-bottom: 1px solid #CDE;
				color: #046 !important;
				text-decoration: none;
			}
			a:hover {
				border: 0;
			}
		}

		footer {
			margin: 0 0 1em;
			color: #99A;
			font-size: .91em;

			img {
				display: block;
				position: absolute;
				top: .6em;
				left: -140px;
				border-radius: 3px;
			}
			time.pop {
				position: absolute;
				top: .2em;
				left: -140px;
				width: 80px;
				color: #99A;
				font-size: 1.6em;
				text-transform: uppercase;
			}
			a.comments-count {
				display: block;
				position: absolute;
				top: 35px;
				left: -140px;
				width: 40px;
				height: 34px;
				margin: .25em 0 0;
				padding: 0;
				background: url(../images/bg-comments-count.png) no-repeat 0 0;
				color: #FFF;
				font-size: 1.5em;
				font-weight: bold;
				line-height: 1.6;
				text-align: center;
				text-decoration: none;
				overflow: hidden;

				&:hover {
					background-position: 0 -50px;
					color: #FFF;
					text-shadow: 0 1px 0 rgba(0, 0, 0, .5);
				}
			}
		}

		h3 {
			margin: 2em 0 1.25em;
			font-size: 1.3em;
			color: #000;
		}
		h4 {
			margin: 2em 0 1.25em;
			color: #046;
			font-size: 1.2em;
		}
		p {
			margin: 0 0 1em;
			font-size: 1.1em;
			line-height: 1.6;
		}
		p.notice {
			margin: 3em 0 0;
			padding: 1em 1.5em;
			border-radius: 5px;
			background-color: $c-blue-light;
			color: $c-link-hover;
			text-shadow: 0 1px 0 #FFF;
		}

		p img {
			display: block;
			margin: .5em 0 1.5em;
			border: 1px solid #DDD;
			padding: 3px;

			&.image-left {
				float: left;
				margin: .4em 20px .6em 0;
			}
			&.image-right {
				float: right;
				margin: .4em 0 .6em 20px;
			}
			&.image-center {
				display: block;
				margin: .4em auto .6em;
			}
			&.no-border {
				border: 0;
				padding: 0;
			}
		}
		p .caption {
			display: block;
			margin: .75em 0 0;
			color: #99A;
			font-size: .85em;
		}

		ul,
		ol {
			margin: 0 0 1em 3em;
			font-size: 1.1em;

			li {
				list-style-position: outside;				
			}
		}
			ul li {
				list-style-type: circle;

			}

		blockquote {
			margin-left: 2em;
			color: #99A;
		}

		pre {
			margin: 0 0 2em !important;
			border-radius: 5px;
			padding: 1.25em 1.5em;
			background-color: #2E3034;
			color: #FFF;
			white-space: pre;
			overflow: auto;

			code {
				font-size: 1em;
			}
			a {
				color: #FFF !important;
			}
		}


		/* Continue Reading */

		> nav {
			margin: 2em 0 0;

			a {
				border-bottom: 1px solid #DDD;
				color: #99A !important;
				font-size: 1.1em;
				font-weight: bold;
				text-decoration: none;
			}
			a:hover {
				border: 0;
			}
		}
	}


		/* Tumblr notes */

		ol.notes {
			margin: 4.5em 0 3em;

			li {
				margin: 0 0 .5em;
				color: #888;
				list-style: none;
			}
			li a {
				color: #554D3A;
			}
			li img.avatar {
				margin-right: .2em;
				vertical-align: -.2em;
			}
		}


		/* Disqus comments */

		#disqus_thread {
			width: 500px;
		}


/* Sidebar
------------------------------ */

#sidebar {
	float: right;
	width: 220px;
	padding-top: 25px;

	section {
		margin: 2em 0;
		padding: .5em 0 0;
		border-top: 1px solid #DDD;
	}


	/* Go back */

	p.go-back {
		margin: 0 0 2em;
		padding-bottom: 2em;
		border-bottom: 1px solid #DDD;
		color: #888;
	}


	/* Introduction */

	p.introduction {
		margin: 0 0 2em;
		padding-bottom: 1.75em;
		border-bottom: 1px solid #DDD;
		color: #888;

		strong {
			padding-left: 19px;
			background: url(../images/i-leaf.png) no-repeat 0 center;
			color: #554D3A;
		}
	}


	/* Subscribe to feed */

	p.subscribe {
		margin: 0 0 2em;

		a {
			padding: .2em 0 .2em 20px;
			background: url(../images/i-rss.png) no-repeat 0 center;
			color: #902E00;
			font-size: 1.1em;
			font-weight: bold;
			text-decoration: none;
		}
		a:hover {
			text-decoration: underline;
		}
	}


	/* Search */

	fieldset {
		position: relative;

		input[type='search'] {
			width: 195px;
		}
		input[type='submit'] {
			display: block;
			position: absolute;
			top: 50%;
			right: 0;
			width: 13px;
			height: 13px;
			margin: -.6em 0 0;
			border: 0;
			background: transparent url(../images/b-search.gif) no-repeat 0 0;
			text-align: left;
			text-indent: -9999px;
			cursor: pointer;
		}
	}
}
