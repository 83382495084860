/* Help Slider
------------------------------ */

.help-slider {
	position: fixed;
	top: 0;
	right: -340px;
	width: 300px;
	height: 100%;
	background: url(../images/bg-help.png) repeat-y 0 0;
	font-size: .8em;
	z-index: 65000;

	.wrapper {
		padding: 10px 10px 10px 39px;

		.spinner {
			top: 30px !important;
		}
	}

	.scroll-area {
		overflow: hidden;

		.wrapper {
			padding: 20px 20px 0 0;
		}
	}

	a.close-help {
		position: absolute;
		top: 20px;
		left: -30px;
		width: 40px;
		height: 40px;
		background: url(../images/b-close-help.png) no-repeat 0 0;
		text-indent: -9999em;

		&:hover {
			background-position: 0 -40px;
		}
	}

	h1 {
		margin: -.15em 0 20px;
		color: #FFF;
		font-size: 1.2em;
		text-shadow: 0 1px 0 #000;
	}
	h2 {
		margin: 1.75em 0 .85em;
		border: 0;
		color: #A6FAA0;
		font-size: 1.1em;
		text-shadow: 0 1px 0 #000;
	}
	a:link {
		color: #CCC;
		text-decoration: underline;
	}
	a:visited {
		color: #999;
		text-decoration: underline;
	}
	a:hover {
		color: #FFF;
	}

	p {
		margin: 0 0 1em;
		color: #999;
		font-size: 1em;
		text-shadow: 0 1px 0 #000;

		a {
			color: #FFF;
			text-decoration: underline;
		}
	}

	code {
		color: #D5FF57;
		font: normal .85em/1.5 $f-monospace;
	}

	ul {
		margin: 0 0 1.5em 1.4em;

		li {
			margin-bottom: .5em;
			color: #999;
			list-style-position: outside;
			list-style-type: disc;
		}
	}

	.mark {
		padding: 0;
		background: none;
		color: #FFF;
		text-shadow: 0 1px 0 #000;
	}
	.highlight {
		color: #CCC;
	}
}
