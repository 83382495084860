/* Curtain */

#curtain {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: $c-brown url(../images/bg-gradient.jpg) repeat-x 0 0;
	opacity: .95;
	z-index: 67000;
}

/* Image Zoom */

@keyframes grow {
	from {
		transform: scale(0);
	}
	0.08% {
		transform: scale(0);
	}
	0.13% {
		transform: scale(1.1);
	}
	0.17% {
		transform: scale(1);
	}
	to {
		transform: scale(1);
	}
}

.image-zoom {
	position: fixed;
	top: 0;
	left: 0;
	border-radius: 2px;
	padding: 5px;
	background-color: #FFF;
	z-index: 68000;
	overflow: hidden;
	box-shadow: 0 1px 4px rgba(0, 0, 0, .5);

	a,
	a img {
		display: block;
		text-decoration: none;
	}
	a img {
		max-width: 100%;
		max-height: 100%;
	}
	a i {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 30px;
		height: 30px;
		background: url(../images/i-zoom-close.png) no-repeat;
	  	background-size: 30px 30px;
	}
}
.image-zoom.loading {
	top: 50%;
	left: 50%;
	width:  80px;
	height: 80px;
	margin: -40px 0 0 -40px;
	padding: 0;

	.spinner {
		top: 50% !important;
		left: 50% !important;	
	}
	img {
		position: absolute;
		left: -9999em;
	}
	i {
		display: none;
	}
}

.image-zoom.grow {
	animation-name: grow;
	animation-duration: 400s;
	animation-iteration-count: 1;
}
