/* Misc
------------------------------ */

.clear {
	clear: both;
}
.hidden {
	display: none !important;
}
.sep {
	margin: 0 .5em;
	color: #888;
}
.centered {
	text-align: center;
}


/* Headers with rules */

.h-rule {
	position: relative;
	display: table;

	.hr-name {
		position: relative;
		display: table-cell;
		width: 2%;
		padding: 0 .75em;
		white-space: nowrap;
	}
	.hr {
		position: relative;
		display: table-cell;
		width: 49%;
		vertical-align: middle;
		overflow: hidden;
	}
		.hr:before,
		.hr:after {
			content: '';
		}
		.hr:before {
			display: block;
			height: 2px;
		}
		.hr:after {
			position: absolute;
			width: 2px;
			height: 8px;
			margin-top: -5px;
		}
		.hr:first-child:after {
			left: 0;
		}
		.hr:last-child:after {
			right: 0;
		}

	&.t-grey {
		color: #595959;

		.hr:before,
		.hr:after {
			background-color: #CCC;
		}
	}

	&.t-purple {
		color: #595980;

		.hr:before,
		.hr:after {
			background-color: #C3C3D2;
		}
	}

	&.t-green {
		color: $c-green-dark;

		.hr:before,
		.hr:after {
			background-color: $c-green-light;
		}
	}
}


/* Leaf */

.i-leaf {
	width: 48px;
	height: 75px;
	background: url(../images/leaf-ls@2x.png) no-repeat;
	background-size: 48px 75px;
}


/* Hiring */

#hiring {
	margin: 0;

	a {
		position: absolute;
		top: 120px;
		left: 0;
		width: 107px;
		height: 73px;
		background: url(../images/hiring.png) no-repeat 0 0;
		text-indent: -9999em;
		z-index: 1000;
	}
}


/* Main button */

input.button {
	display: inline-block;
	margin: 0;
	border: 2px solid $c-form-focus-border;
	border-radius: 5px;
	padding: 10px 30px;
	background: #9ACF9F url(../images/b-submit.gif) repeat-x 0 0;
	color: #000;
	font: bold 1em $f-sansserif;
	text-decoration: none;
	text-shadow: 0 1px 0 #E7FFD5;
	white-space: nowrap;

	&:hover,
	&:active {
		border-color: #116746;
		background-color: #55B166;
		background-position: 0 -50px;
		color: #FFF;
		text-shadow: 0 1px 0 #393;
		cursor: pointer;
	}
}

input.button-big {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	border: 2px solid #71A871;
	border-radius: 5px;
	padding: 1em 3em 1.2em;
	background: #CDF2B6 url(../images/button-big-fallback.png) repeat-x 0 50%;
	background-image: linear-gradient(to bottom, #CDF2B6, #91CC89);
	color: #004C33;
	font: normal normal 16px $f-accent;
	-webkit-font-smoothing: antialiased;
	text-shadow: 0 1px 2px rgba(#FFF, .6);
	box-shadow: inset 0 2px 2px rgba(#FFF, .75);

	&:hover {
		background-image: linear-gradient(to bottom, lighten(#CDF2B6, 5%), darken(#91CC89, 3%));
		box-shadow: inset 0 1 5px rgba(#FFF, .9);
	}
	&:disabled {
		border-color: lighten(desaturate(#71A871, 5%), 20%);
		background-color: desaturate(#CDF2B6, 20%);
		background-image: none;
		color: mix(desaturate(#CDF2B6, 20%), #004C33, 50%);
		box-shadow: none;
	}
}
input.button-big--flat {
	border: 2px solid #064;
	padding: .8em 4em .9em;
	background: #064 none;
	color: #FFF;
	text-shadow: none;
	box-shadow: none;

	&:hover {
		border-color: #007951;
		background: #007951 none;
	}
	&:disabled {
		border-color: #CDD4D2;
		background: #CDD4D2 none;
		color: #E5EBE9;
	}
}


/* Tooltips */

.tooltip {
	position: absolute;
	max-width: 180px;
	border-radius: 3px;
	padding: .5em .75em;
	background-color: rgba(0, 0, 0, .85);
	font-size: .8125em;
	box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
	z-index: 65000;

	p {
		margin: 0;
		color: #FFF;
		font-size: .85em;
		line-height: 1.4;

		span {
			color: #999;
		}
	}
}


/* Global Notification (IE6 Support) */

#global-notification {
	margin: 0;
	border-bottom: 3px solid #E5A400;
	padding: 1.5em .5em;
	background-color: #FFE066;
	color: #000;
	font-weight: bold;
	font-size: .8em;
	text-align: center;
	overflow: hidden;

	p {
		margin: 0;
	}
	a {
		color: #900;
	}
}
