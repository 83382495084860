/* Referrers
============================== */

#referrer {
	border-width: 0 0 3px;
	border-style: solid;
	font-size: .8em;

	.wrap {
		position: relative;
		width: 440px;
		margin: 0 auto;
		padding: 20px 310px 20px 45px;
		text-align: left;
		line-height: 1.4;
	}
	h3 {
		margin: 0;
		font-size: 1.2em;
		color: #FFF;
	}
	p {
		margin: .5em 0 1em;
	}
	p:last-child {
		margin-bottom: 0;
	}
	code {
		font-weight: bold;
	}
	a {
		font-weight: bold;
	}
	img {
		position: absolute;
		top: 2.5em; right: 0;
	}
}

/* Versions */

.ref-versions {
	border-color: #000;
	background: #000 url(../images/bg-ref-versions.gif) repeat-x 0 0;
	color: #BBB;

	.wrap {
		background: url(../images/logos/versions-ref.gif) no-repeat 100% 100%;
	}
	a,
	code {
		color: #EEE;
	}
}

/* Cornerstone */

.ref-cornerstone {
	border-color: #222;
	background: #262729 url(../images/bg-ref-cornerstone.png) repeat-x 0 100%;
	color: #B6BCC5;

	.wrap {
		background: url(../images/logos/cornerstone-ref.png) no-repeat 100% 100%;
	}
	a,
	code {
		color: #F0F0F0;
	}
}

/* Lighthouse */

.ref-lighthouse {
	border-color: #3B414A;
	background: #282D33 url(../images/bg-ref-lighthouse.gif) repeat-x 0 0;
	color: #7B7B7B;

	.wrap {
		background: url(../images/logos/lighthouse-ref.png) no-repeat 100% center;
	}
	a,
	code {
		color: #BBB;
	}
}

/* Harvest */

.ref-harvest {
	border-color: #FE9F43;
	background-color: #F57A00;
	color: #FFDFBF;

	.wrap {
		background: url(../images/logos/harvest-ref.gif) no-repeat 100% center;
	}
	a,
	code {
		color: #FFF;
	}
}

/* Stack Overflow */

.ref-stackoverflow {
	border-color: #AAA;
	background-color: #DDD;
	color: #444;

	.wrap {
		background: url(../images/logos/stackoverflow-ref.png) no-repeat 100% 35%;
	}
	h3,
	a,
	code {
		color: #000 !important;
	}
}
