/* Small button */

a.button-sml {
	box-sizing: border-box;
	display: inline-block;
	height: 26px;
	padding: 4px 30px 0;
	border: 1px solid #A7A7A7;
	border-radius: 13px;
	background-color: #DBDBDB;
	background-image: linear-gradient(to bottom, #FFF, #DBDBDB);
	color: #333;
	font-size: .95em;
	text-shadow: 0 1px 0 #FFF;
	text-decoration: none;
	box-shadow: 0 0 0 2px rgba(#808080, .2);

	&:hover {
		border-color: #999;
		background-color: #CDCDCD;
		background-image: linear-gradient(to bottom, #FFF, #CDCDCD);
		color: #000;
		box-shadow: 0 0 0 2px rgba(#808080, .25);
	}
}


/* Old Pricing */

#pricing-old {
	.customers-title {
		color: $c-green-dark;
		text-shadow: none;
		font-size: 1.3em;
	}

	#pricing {
		position: relative;
		margin: 40px 0;
	}

	#pricing hgroup {
		margin: -2em 0 2em;
		padding: .5em 0;
		text-align: center;
	}
		#pricing hgroup h1 {
			margin: 0 0 .25em;
			font-size: 1.3em;
		}
		#pricing hgroup h2 {
			margin: 0;
			color: #888;
			font-size: 1.1em;
			font-weight: normal;
		}

	#pricing p.referral-notice {
		padding: 0 0 2em;
		text-align: center;
	}
		#pricing p.referral-notice strong {
			display: inline-block;
			margin: 0 auto;
			padding: .4em 1em;
			border-radius: 3px;
			background-color: #FE6;
			color: #4D4636;
			font-size: 1.1em;
			font-weight: bold;
			text-align: center;
		}
		
		

	/* Plans
	------------------------------ */

	.plans {
		margin: 0 -20px;
		padding: 0;
		overflow: hidden;
	}
		.plans header {
			position: relative;
			float: left;
			width: 280px;
			height: auto;
			padding: 3em 0 0 20px;
			background: none;
		}
			.plans header h2 {
				display: inline-block;
				position: absolute;
				top: 0; left: 20px;
				margin: 0;
				padding: .2em 1em .3em;
				border-radius: 0 3px 3px 0;
				font-size: .85em;
				text-shadow: 0 1px 0 rgba(0, 0, 0, .5);
				text-transform: uppercase;
			}
			.plans header ul {
				margin: .4em 0 0;
			}
				.plans header ul li {
					margin: 0 0 .75em;
					padding-left: 17px;
					background: url(../images/i-plans.png) no-repeat 0 0;
					font-weight: bold;
					list-style-type: none;
				}
				.plans header ul li.include {
					padding: 0;
					background: none;
					color: #888;
					font-weight: normal;
				}
		.plans abbr {
			border-bottom-style: dashed;
			border-bottom-width: 1px;
			cursor: help;
		}   
		.plans table {
			float: right;
			width: 620px;
		}
			.plans table th,
			.plans table td {
				width: 140px;
				padding: .5em 10px;
			}
			.plans table th,
			.plans table td {
				border-left-style: solid;
				border-left-width: 1px;
				font-weight: bold;
				text-align: center;
			}
			.plans table th {
				font-weight: normal;
				text-align: right;
			}
				.plans table th span {
					font-size: .85em;
				}
			.plans table td.plan-name {
				padding: 1.5em 0 1em;
			}

			.bronze,
			.silver,
			.gold,
			.platinum,
			.diamond {
				display: inline-block;
				position: relative;
				padding: 0 .4em 1px;
				border-radius: 2px;
				font-weight: bold;
				font-size: 11px;
				text-shadow: 0 1px 0 #FFF;
				text-transform: uppercase;
				box-shadow: 0 1px 2px rgba(0, 0, 0, .7);
			}
			.bronze   { background-color: #FAEED7; color: #85562C; }
			.silver   { background-color: #EEE; color: #555; }
			.gold     { background-color: #FFF7B1; color: #7B6E00; }
			.platinum { background-color: #F3FBFF; color: #516672; }
			.diamond  { background-color: #D7FAFF; color: #10545E; }
			
			.diamond i {
				display: block;
				position: absolute;
				top: -4px;
				left: -4px;
				width: 14px;
				height: 14px;
				background: url(../images/i-blink.png) no-repeat 0 0;
			}


			.plans table tr.price td {
				font-size: 1.2em;
			}
				.plans table tr.price td span {
					font-size: .7em;
					font-weight: normal;
				}
			.plans table tr.buttons td {
				padding: 20px 0;
			}
			.plans table td.trial-account {
				width: 120px;
				padding: 0 20px;
				color: #888 !important;
				font-size: .85em;
				font-weight: normal;
				vertical-align: middle;
			}
				.plans table td.trial-account h3 {
					margin: 0 0 .75em;
					padding: 0;
					color: #666;
					font-size: 1.1em;
				}


	/* Personal Plans */

	.plans.personal {
		border-radius: 5px 0 0 5px;
		background-color: #F7F7F7;
		background-color: rgba(229, 229, 229, .3);
	}
		.plans.personal h2 {
			background-color: #CCC;
			color: #333;
			text-shadow: 0 1px 0 #EEE;
		}
		.plans.personal header li {
			background-position: 0 5px;
			color: #555;
		}
			.plans.personal header li a {
				color: #555;
			}
		.plans.personal table th,
		.plans.personal table td {
			border-color: #EEE;
			color: #555;
		}
		.plans.personal table th {
			color: #888;
		}
		.plans.personal table tr.price {
			color: #333;
		}
			.plans.personal table tr.price span {
				color: #888;
			}


	/* Business Plans */

	.plans.business {
		border-top: 1px solid #D5D5E8;
		border-radius: 0 5px 5px 0;
		background-color: #EFEFFF;
		background-color: rgba(102, 102, 255, .1);
	}
		.plans.business h2 {
			background-color: #556;
			color: #EFEFFF;
		}
		.plans.business header li {
			background-position: 0 -45px;
			color: #889;
		}
		.plans.business header li {
			font-weight: normal;
		}
			.plans.business header li a {
				color: #556;
			}
		.plans.business header li.plus {
			background-position: 0 -97px;
			font-weight: bold;
			color: #556;
		}
		.plans.business table th,
		.plans.business table td {
			border-color: #E6E6F5;
			color: #556;
		}
		.plans.business table th {
			color: #889;
		}
		.plans.business table tr.price {
			color: #334;
			text-shadow: 0 1px 0 #EFEFFF;
		}
			.plans.business table tr.price span {
				color: #889;
			}
		.plans.business abbr {
			border-color: #BBC;
		}


	/* FAQ */

	#faq {
		margin: 0;
		padding: 2em 150px;
		overflow: hidden;
	}
		#faq .column {
			float: left;
			width: 300px;
			margin: 0 10px 2em;
		}
			#faq h2 {
				margin: 2em 0 1em;
				font-size: 1.1em;
			}
			#faq p {
				margin: 0 0 1em;
				color: #888;
				line-height: 1.5;
			}



	/* Pricing: Versions B and C
	============================== */

	/* 15-day Guarantee Ribbon */

	.guarantee-ribbon {
		position: absolute;
		top: 88px;
		left: 50%;
		width: 318px;
		height: 160px;
		margin-left: 311px;
		background: url(../images/guarantee-ribbon.png) no-repeat;
		overflow: hidden;
	}
		.guarantee-ribbon h1,
		.guarantee-ribbon p {
			margin: 0;
			border: 0;
			padding: 0;
			font-weight: normal;
			font-size: 1em;
			line-height: normal;
			text-indent: -9999em;
		}


	/* Page title */

	.page-title {
		margin: 70px 0;
		overflow: hidden;
	}
		.page-title h1,
		.page-title h2 {
			margin: 0;
			border: 0;
			padding: 0;
			text-align: center;
		}
		.page-title h1 {
			color: #000;
			font-size: 29px;
		}
		.page-title h2 {
			margin-top: .33em;
			color: #666;
			font-weight: normal;
			font-size: 18px;
		}


	/* Plans */

	.plans-lineup {
		position: relative;
		margin: 0 -20px 60px;
		padding-top: 50px;
	}
		.plans-lineup:before,
		.plans-lineup:after {
			content: '';
			display: table;
		}
		.plans-lineup:after {
			clear: both;
		}

		.plans-lineup i.i-leaf {
			position: absolute;
			top: 210px;
			left: -48px;
		}


		.plan {
			position: relative;
			float: left;
			width: 190px;
			height: 245px; /* TODO */
			text-align: center;
			z-index: 50;
		}
		.plan.first {
			border-radius: 4px 0 0 4px;
		}
		.plan.last {
			border-radius: 0 4px 4px 0;
		}
			.plan-name {
				margin: -10px 0 1.25em;
				font-size: 1em;
			}

			.plan-price {
				margin: 2em 0 2.75em;
				color: #666;
				font-size: .78125em;
				text-transform: uppercase;
			}
				.plan-price strong {
					margin-right: .1em;
					color: #292929;
					font-size: 1.8em;
				}

			.plan-includes {
				margin: 0 0 2.75em;
				padding: 0;
				color: #8A8A8A;
				font-size: 1.09375em;
			}
				.plan-includes li {
					margin: 0 0 .5em;
					list-style: none;
				}
				.plan-includes strong {
					color: #666;
				}

			.label-popular {
				position: absolute;
				top: -2px;
				left: -2px;
				width: 69px;
				height: 69px;
				background: url(../images/label-most-popular.png) no-repeat;
				text-indent: -9999em;
				overflow: hidden;
			}


			.square-button {
				border: 1px solid #9F9F9F;
				border-radius: 4px;
				padding: .4em 2em .6em;
				background-color: #DADADA;
				background-image: url(../images/bg-square-btn.png);
				background-image: linear-gradient(to bottom, #FFF, #DADADA);
				color: #444 !important;
				font-weight: bold;
				font-size: 15px;
				line-height: 1;
				text-decoration: none;
				box-shadow: 0 0 0 3px rgba(0, 0, 0, .08);
			}
			.square-button:hover {
				border-color: #888;
				background-color: #D0D0D0;
				background-image: linear-gradient(to bottom, #FFF, #D0D0D0);
				color: #111 !important;
				box-shadow: 0 0 0 3px rgba(0, 0, 0, .12);
			}
			.square-button.t-purple {
				border-color: #9F9FA6;
				background-color: #DADAE0;
				background-image: linear-gradient(to bottom, #FFF, #DADAE0);
				color: #445 !important;
				box-shadow: 0 0 0 3px rgba(76, 76, 102, .10);
			}
			.square-button.t-purple:hover {
				border-color: #8E8E9E;
				background-color: #D1D1DE;
				background-image: linear-gradient(to bottom, #FFF, #D1D1DE);
				color: #112 !important;
				box-shadow: 0 0 0 3px rgba(0, 0, 0, .13);
			}


		.plan-personal {
			background-color: #F3F3F3;
		}
		.plan-personal.first {
			background-image: radial-gradient(ellipse, #E3E3E3, #F3F3F3);
			background-size: 10px 80%;
			background-repeat: no-repeat;
			background-position: 185px 50%;
		}
			.plan-personal .plan-price {
				color: #666;
			}
			.plan-personal .plan-price strong {
				color: #292929;
			}
			.plan-personal .plan-includes {
				color: #8A8A8A;
			}
			.plan-personal .plan-includes strong {
				color: #666;
			}

		.plan-popular {
			width: 220px;
			height: 265px;
			margin-top: -10px;
			border-radius: 5px;
			background-color: #DFDFF2;
			background-image: linear-gradient(to bottom, #F0F0FA 0%, #FAFAFF 33%, #DFDFF2 100%);
			z-index: 100;
			box-shadow: 0 1px 4px 0 rgba(76, 76, 102, .60);
		}
			.plan-popular:before {
				content: '';
				position: absolute;
				top: 3px;
				right: 3px;
				bottom: 3px;
				left: 3px;
				border: 2px solid #FFF;
				border-radius: 4px;
				z-index: -1;
			}

			.plan-popular .plan-price {
				margin: 2.5em 0;
				color: #575766;
				font-size: .859375em;
			}
			.plan-popular .plan-price strong {
				color: #292933;
			}
			.plan-popular .plan-includes {
				color: #7A7A87;
				font-size: 1.203125em;
			}
			.plan-popular .plan-includes strong {
				color: #4F4F59;
			}

		.plan-business {
			background-color: #EAEAF9;
		}
		.plan-business.last {
			background-image: radial-gradient(ellipse, #DADAEB, #EAEAF9);
			background-size: 10px 80%;
			background-repeat: no-repeat;
			background-position: -5px 50%;
		}
			.plan-business .plan-price {
				color: #5C5C66;
			}
			.plan-business .plan-price strong {
				color: #292933;
			}
			.plan-business .plan-includes {
				color: #80808B;
			}
			.plan-business .plan-includes strong {
				color: #5C5C66;
			}


	/* Plans groups */

	.plans-group {
		position: absolute;
		top: 0;
		margin: 0;
		text-align: center;
		font-weight: bold;
		font-size: 1.09375em;
	}
		.plans-personal {
			left: 0;
			width: 378px;
			color: #595959;
		}
			.h-rule.plans-personal .hr:before,
			.h-rule.plans-personal .hr:after {
				background-color: #CCC;
			}

		.plans-business {
			right: 0;
			width: 598px;
			color: #595980;
		}
			.h-rule.plans-business .hr:before,
			.h-rule.plans-business .hr:after {
				background-color: #C3C3D2;
			}


	/* Business features */

	.business-features {
		clear: both;
		float: right;
		width: 600px;
		padding-top: 65px;
		background: url(../images/business-features.png) no-repeat 0 25px;
		color: #595980;
		font-weight: bold;
		font-size: 1.09375em;
		line-height: 1.4;
	}
		.business-features ul {
			margin: 0;
			padding: 0;
		}
			.business-features li {
				margin: .5em 0;
				padding: 0;
				list-style: none;
				text-align: center;
			}
			.business-features li:before {
				content: '';
				display: inline-block;
				width: 10px;
				height: 8px;
				margin-left: -15px;
				margin-right: 5px;
				background: url(../images/i-checkmark-purple.png) no-repeat;
				vertical-align: .1em;
			}


	/* Trial */

	.tryout {
		float: left;
		width: 380px;
		padding-top: 65px;
		color: #888;
		text-align: center;
		line-height: 1.4;
	}
		.tryout p {
			margin: .75em 0 0;
		}


	/* Descending order */

	.descending {
	}
		.descending .plan-business.first {
			background-image: radial-gradient(ellipse, #DADAEB, #EAEAF9);
			background-size: 10px 80%;
			background-repeat: no-repeat;
			background-position: 185px 50%;
		}
		.descending .plan-personal.last {
			background-image: radial-gradient(ellipse, #E3E3E3, #F3F3F3);
			background-size: 10px 80%;
			background-repeat: no-repeat;
			background-position: -5px 50%;
		}

		.descending .plans-personal {
			right: 0;
			left: auto;
		}
		.descending .plans-business {
			right: auto;
			left: 0;
		}

		.descending .business-features {
			float: left;
		}
		.descending .tryout {
			float: right;
		}
}
