/* Layout
============================== */

.page {
	min-width: 960px;
	padding: 10px 20px;
	background: url(../images/bg-page-pattern.png) no-repeat 50% -11px;
	font-size: .8em;

	.container {
		width: 940px;
		margin: 0 auto;
	}
}

.page.alt-short {
	border-top: 1px solid $c-brown-lightest;
	background-position: 50% -160px;
}

.page.alternative {
	border-top: 1px solid $c-brown-lightest;
	background-image: url(../images/bg-page-pattern-alt.png);
}
.page.alternative.light {
	background-image: none;
}


/* Footer
------------------------------ */

body > footer {
	width: 940px;
	margin: 0 auto;
	padding: 10px 0;
	font-size: .8em;

	hr {
		margin: 0 -20px 1em;
	}
	section {
		float: left;
		width: 280px;
		margin-right: 50px;

		h1 {
			margin-bottom: 1.25em;
			font-size: 1.2em;
		}
		p {
			margin: .25em 0 .75em;
			line-height: 1.6;
		}

		/* Hosting */

		.rackspace {
			float: right;
		}
	}
	.container {
		clear: both;
		padding: 40px 0;
		color: mix(#FFF, $c-brown, 60%);
		text-align: center;
		font-size: .85em;

		a {
			color: inherit;
		}
		ul {
			margin-bottom: .5em;
		}
		ul li {
			display: inline;
			margin: 0 .75em;
			list-style: none;
		}
	}
}

	/* Help */

	footer,
	#sidebar {

		#help ul {
			margin-left: 0;

			li {
				margin-bottom: .75em;
				padding-left: 20px;
				background-image: url(../images/i-help.png);
				background-repeat: no-repeat;
				list-style: none;

				&.support { background-position: 0 1px; }
				&.chat    { background-position: 0 -47px; }
				&.help    { background-position: 0 -98px; }
				&.twitter { background-position: 0 -149px; }
			}
		}
		#help small {
			display: block;
		}
	}


	/* Newsletter */

	#newsletter {

		fieldset {
			margin: -.25em 0 1em;
			border-radius: 4px;
			padding: .75em 0 .75em .75em;
			background-color: #F5F5F5;
		}
		input[type='email'] {
			width: 14em;
		}
	}


	/* Wildbit */

	#wildbit {
		margin-right: 0;

		h2 {
			background: url(../images/logo-wildbit.png) no-repeat 4.75em .2em;
		}
		h2 a {
			display: inline-block;
			width: 60px;
			height: 17px;
			text-indent: -9999px;
		}
		a.link-postmark,
		a.link-conveyor {
			padding: 0 .25em;
			border-radius: 2px;
			font-weight: bold;
			text-decoration: none;
		}
		a.link-postmark:hover,
		a.link-conveyor:hover {
			background-color: #000;
			color: #FFF;
		}
		a.link-postmark {
			background-color: #FFDE00;
			color: #333;
		}
		a.link-conveyor {
			background-color: #AD0057;
			color: #FFF;
		}
	}
