/* Partners & Security
============================== */

section.columns {
	padding: 40px 0 0;
	overflow: hidden;

	hgroup {
		float: left;
		width: 220px;
		text-align: right;

		h1 {
			margin: 0;
		}
		h2 {
			margin: .75em 0 0;
			color: #888;
			line-height: 1.5;
			font-weight: normal;
		}
	}
	.wrap {
		float: right;
		width: 640px;
	}
		section {
			float: left;
			width: 300px;
			margin: 0 0 20px 20px;

			&:last-child {
				margin-bottom: 30px;
			}
			h2 {
				margin: 0 0 1.4em;
				padding-left: 21px;
				background: url(../images/i-leaf.png) no-repeat 0 .3em;
				color: #554D3A;
			}
			h3 {
				margin: 0 0 .75em;
				color: $c-form-focus-border;
				font-size: 1.2em;
			}
			p {
				color: #888;
			}
		}

		dl {
			float: left;
			width: 300px;
			margin: 0 0 40px 20px;
			color: #888;
			line-height: 1.5;
			font-size: .9em;

			dt {
				position: relative;
				height: 80px;
				margin-top: -35px;
				line-height: 80px;
			}
			dt img {
				display: inline;
				vertical-align: middle;
			}

			dd {
				margin: .75em 0 40px;
			}
			dd p {
				margin-top: 1.6em;
			}

			strong a {
				color: #6E9100;
			}
		}


	&.col-3 {

		hgroup {
			margin-top: 4px;
		}
		dl {
			width: 193px;
			margin-bottom: 0;
		}
			dl dt {
				height: 36px;
				margin: 0;
				line-height: 36px;
			}
	}

	&.col-4 {

		hgroup {
			margin-top: 4px;
		}
		dl {
			width: 140px;
			margin-bottom: 0;
		}
			dl dt {
				height: 36px;
				margin: 0;
				line-height: 36px;
			}
	}

	/* Buzz */

	blockquote {
		margin-left: 20px;
		font-size: 1.2em;
		line-height: 1.6;

		p {
			color: #554D3A;
		}
		p:before,
		p:after {
			line-height: .75em;
			font-weight: bold;
			font-size: 1em;
		}
		p:before {
			content: '“';
			margin-left: -.65em;
			margin-right: .2em;
		}
		p:after {
			content: '”';
			margin-left: .2em;
		}
	}
	footer {
		margin: 0 0 40px 20px;

		p {
			display: table-cell;
			color: #888;
		}
		img {
			display: inline-block;
			margin-top: -.2em;
			vertical-align: middle;
		}
		span.sep {
			color: #DDD;
		}
	}
}

section#integration dl:nth-child(n+3) {
	margin-bottom: 0;
}
section#partners dl dt {
	height: 100px;
	line-height: 100px;
}
section#tools dl dt {
	height: 70px;
	margin-top: -20px;
	line-height: 70px;
}


p.highlight {
	margin: 30px 0;
	color: #99A;
	font-size: 1.5em;
	text-align: center;

	strong {
		color: #333;
		font-weight: normal;
	}
	span {
		color: #DDD;
	}
}	

span.notice {
	padding: .1em .2em;
	border-radius: 2px;
	background-color: $c-blue-light;
	color: $c-link-hover;
	text-shadow: 0 1px 0 #FFF;
}
