/* Release Notes
============================== */

#release-notes {

	p.loading {
		margin: 0;
		padding: 80px 0;
		text-align: center;
	}
	article {
		position: relative;
		width: auto;
		margin: 0 -40px 40px;
		border-bottom: 2px solid #E9E8E5;
		padding: 0 40px 40px;

		&:last-child {
			margin-bottom: 0;
			border: 0;
			padding-bottom: 20px;
		}
	
		h1 {
			margin: 0 0 40px;
			color: #999;
			font-size: 1.5em;
		}
		h2 {
			margin: 0 0 1em;
			padding-left: 20px;
			color: #999;
			font-size: 1.2em;

			&.h-features {
				background: url(../images/i-leaf.png) no-repeat 0 center;
				color: #3B783F;
			}
			&.h-bugfixes {
				background: url(../images/i-bug.png) no-repeat 0 center;
				color: #7E1404;
			}
		}		
		ul {
			margin: 0 0 40px;

			&:last-child {
				margin: 0;
			}
			li {
				margin: 0 0 1.5em;
				padding-left: 20px;
				background: url(../images/i-bullet.gif) no-repeat 0 5px;
				font-size: .9em;
				list-style-type: none;

				&:last-child {
					margin: 0;
				}
			}
		}
	}
}
