/* Error 404
============================== */

#minimal.error {
	header {
		z-index: 100;

		hgroup {
			position: static;
			width: 330px;
			height: 57px;
			margin: 0 auto;
			padding: 51px 0 0;

			h1,
			h2 {
				width: 330px;
				margin: 0;
				background-image: url(../images/header-404.png);
				background-repeat: no-repeat;
				color: #FFF;
				text-indent: -9999em;
				font-size: .8em;
			}
			h1 {
				height: 40px;
				background-position: 0 0;
			}
			h1.help-moved {
				width: 454px;
				height: 41px;
				margin-left: -60px;
				background: url(../images/header-moved.png);
			}
			h2 {
				display: block !important;
				height: 17px;
				background-position: 0 -40px;
			}
			a {
				display: block;
				height: 100%;
			}
		}

		b.leaf {
			position: absolute;
			top: 71px; left: 50%;
			width: 175px;
			height: 166px;
			margin-left: -387px;
			background: url(../images/leaf-error.png) no-repeat 0 0;
		}
	}

	.page {
		position: relative;
		z-index: 500;

		.container {
			padding: 40px;
			background-color: #E5E2DA;
		}
		h2 {
			margin: 1.2em 0 .4em;
		}
	}
}
