/* Sign Up
============================== */

/* Minimal Layout */

#minimal {
  background: $c-brown url(../images/bg-header-pattern-alt.jpg) repeat-x 50% 0;

  header,
  header .container {
    height: 140px;
    background: transparent none;
  }
  header hgroup.navless {
    position: static;
    width: 620px;
    margin: 0 auto;
    padding: 20px 0 0;
  }
  header hgroup h2 {
    display: none;
  }
  .page {
    background: transparent none;
  }
  .page .container {
    width: 620px;
    border-radius: 5px;
    padding: 30px 40px;
    background-color: #FFF;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .7);
  }

  > footer .container,
  > footer .container a {
    color: mix(#FFF, $c-brown, 40%);
  }
}


/* Summary */

.summary {
  width: 620px;
  height: 3em;
  margin: 20px auto;
  text-shadow: 0 1px 1px #000;
  overflow: hidden;

  h2 {
    float: left;
    margin: 0;
    color: #FFF;
    line-height: 1.4;
    font-size: 1.8em;
  }
  h2.centered {
    float: none;
  }
    .steps {
      margin-right: .33em;
      color: rgba(255, 255, 255, .5);
      font-weight: normal;
      font-size: .75em;
    }
    .steps i {
      font-family: $f-serif;
    }

  p {
    float: right;
    margin: 0;
    color: $c-lime;
    line-height: 2.4;
    text-align: right;
    font-weight: bold;
    font-size: 1.2em;
  }
    p a {
      border-bottom: 1px dashed mix($c-lime, $c-brown, 33%);
      color: $c-lime;
      text-decoration: none;
    }
    p a:hover {
      border-bottom-style: solid;
    }
    p del {
      margin-right: .2em;
      color: mix($c-lime, $c-brown, 33%);
      font-weight: normal;
    }
    p ins {
      margin-right: .2em;
      color: $c-yellow-lightest;
    }
}


/* Form */

#signup fieldset {
  margin: 0 -40px;
  padding: 0 40px;
  overflow: hidden;

  legend {
    font-size: 1.4em;
  }
  p.helper {
    margin: .5em 0 2em;
    color: #888;
    font-size: .95em;
  }

  &.alt {
    margin-top: 30px;
    margin-bottom: 30px;
    border-width: 1px 0;
    border-style: solid;
    border-color: #E9E9E9;
    padding-top: 30px;
    padding-bottom: 30px;
    background: url(../images/bg-page-pattern-alt.png) no-repeat 55% 0;

    &.last {
      margin-bottom: -30px;
      border-bottom: 0;
    }
    .form-row:last-child {
      margin-bottom: .75em;
    }
  }
}


/* Coupon Code */

#signup #discount,
#signup #discount-ask {
  margin-bottom: 30px;
}
#signup #discount-ask {

  a {
    border-bottom: 1px dashed #CCC;
    color: #888;
    text-decoration: none;
  }
  a:hover {
    border-bottom-style: solid;
    color: #000;
  }
  a.disabled {
    border: 0;
    color: #888 !important;
    text-decoration: line-through;
    cursor: default;
  }
  .form-field span {
    margin-left: .5em;
  }
}


/* Submit button */

#signup .form-submit {
  margin: 30px -40px -30px;
  border-top: 1px solid $c-olive;
  border-radius: 0 0 5px 5px;
  padding: 40px;
  background-color: $c-olive-light;
  color: $c-olive-dark;
  text-align: center;
  overflow: hidden;

  p.helper {
    margin: 1.5em 0 0;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .75);
  }
  p.helper a {
    color: inherit;
  }
  p.helper a:hover,
  p.helper strong {
    color: darken($c-olive-dark, 9%);
  }
}
