@use 'sass:math';

html,
body {
	min-height: 100%;
}
.spring {
	background-color: $c-spring-bg;
	background-image: radial-gradient(circle, #F2FFE5, rgba(#F2FFE5, 0));
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
}
	.spring-wrap {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url(../images/spring-leaves.png) no-repeat 50% 0;
	}


// Logo

.b-logo {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 163px;
	height: 206px;
	background: url(../images/b-logo@2x.png) no-repeat;
	background-size: 100%;
	text-indent: -9999em;
	overflow: hidden;
}


// Text boxes

.textbox {
	box-sizing: border-box;
	position: absolute;
	margin-top: 20px;
	border-radius: 3px;
	padding: .6em 1em;
	color: $c-green-dark;
	font-weight: bold;
	font-size: .8em;
	text-shadow: 0 1px 1px rgba(#FFF, .5);
	white-space: nowrap;
}
	.textbox--back {
		left: 20px;
		border: 2px solid mix($c-spring-bg, $c-spring-green, 25%);
		border-color: rgba($c-spring-green, .75);
		text-decoration: none;

		&:link,
		&:visited {
			color: $c-green-dark;
		}
		&:hover,
		&:active {
			border: 2px solid $c-spring-green;
			color: $c-green-darkest;
		}

		.i-back {
			display: inline-block;
			width: 0;
			height: 0;
			margin-right: .2em;
			border-top:    5px solid transparent;
			border-bottom: 5px solid transparent; 
			border-right:  5px solid;
		}
	}

	.textbox--step {
		right: 20px;
		background-color: rgba($c-spring-green, .25);
		color: mix($c-spring-bg, $c-green-darkest, 25%);
	}


// Page container

.page-container {
	width: 95%;
	min-width: 790px;
	margin: 0 auto;
	font-size: .8em;
	overflow: hidden;
}
	.spring-page-title {
		margin: 40px 0 45px;
		color: #064;
		font: normal normal 2.1875em/1.2 $f-accent; // 28px / (16px * 0.8em) = 2.1875
		text-shadow: 0 1px 0 #FFF;
		text-align: center;
		-webkit-font-smoothing: antialiased;
	}
	.spring-page-subtitle {
		margin: -2.5em 0 3em;
		color: #5B8C7C;
		font-weight: bold;
		font-size: 1.2em;
		text-align: center;
		text-shadow: 0 1px 3px rgba(#FFF, .75);
	}
		.icon-ssl {
			display: inline-block;
			width: 12px;
			height: 15px;
			margin-right: .33em;
			background: url(../images/i-ssl.svg) no-repeat;
			vertical-align: -.1em;
		}


// Form

.hr-legend {
	box-sizing: border-box;
	position: relative;
	width: $central-field;
	margin: 0 auto;
	padding: 3em 20px .5em;
	color: #365;
	font: normal normal 1.25em $f-accent;
	text-shadow: 0 1px 2px rgba(#FFF, .75);
	text-align: center;
	-webkit-font-smoothing: antialiased;

	.hr-name {
		padding-bottom: .2em;
	}
	.hr:before,
	.hr:after {
		background-color: #BCDCC1;
	}

	// Firefox positions generated content relative to the parent, so this is a workaround:
	.hr {
		position: static;

		&:after {
		}
		&:first-child:after {
			margin-left: 20px;
		}
		&:last-child:after {
			margin-right: 20px;
		}
	}
}

.form-trio-line {
	margin: 1.25em 0 1.5em;

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}

	.form-label,
	.form-comment {
		width: math.div((100% - $central-field), 2);
	}
	.form-field {
		box-sizing: border-box;
		float: left;
		width: $central-field;
		margin-left: 0;
		padding-right: 20px;
		padding-left: 20px;
	}

	.form-comment {
		float: right;
		display: table;
		height: 3em;    // input's height: 0.2 * (0.6 * 2 + 1.3)
		margin: 1px 0;  // Match input's border

		p {
			display: table-cell;
			vertical-align: middle;
		}
	}
	.form-text {
		clear: both;
		box-sizing: border-box;
		margin-left: math.div((100% - $central-field), 2);
		padding: .33em 20px 0;
	}

	&.form-error {
		padding-top: 0;
		padding-bottom: 0;
		background-color: transparent;

		.form-label,
		.error-msg {
			color: $c-bad;
		}
		.error-msg:before {
			@extend %warning-icon;
		}
	}
}

.form-row--vertical {
	.form-label {
		float: none;
		width: auto;
		padding-bottom: .25em;
		text-align: left;

		.meta {
			color: #999;
			font-size: .8em;
		}
	}
	.form-field,
	.form-text,
	.form-comment {
		margin-left: 0;
	}
	.form-field {
		select {
			margin-right: .75em;
			margin-left: .15em;
			font-size: large;
		}
	}
	.url-preview--plate {
		background-color: #FCFCFC;
	}

	&.form-error {
		margin-right: 0;
		margin-left: 0;
		border-radius: 0;
		padding: 0;
		background-color: transparent;

		.form-label,
		.error-msg {
			color: $c-bad;
		}
		.error-msg:before {
			@extend %warning-icon;
		}
	}
}

.form-centered {
	text-align: center;

	.form-field {
		margin-left: 0;
	}

	&.form-error {
		padding-top: 0;
		padding-bottom: 0;
		background-color: transparent;
		color: $c-bad;

		a {
			color: inherit;
		}
		a:hover {
			color: darken($c-bad, 20%);
		}
	}
}


@media only screen and (min-width: 950px) {

	.page-container {
		width: 92.5%;
	}
}

@media only screen and (min-width: 1280px) {

	.page-container {
		width: 90%;
	}
}

@media only screen and (min-height: 900px) {

	.spring-page-title {
		margin-top: 2.1em;
		margin-bottom: 2.5em;
		font-size: 2.5em;  // 32px / (16px * 0.8em) = 2.5
		line-height: 1.33;
	}
	.spring-page-subtitle {
		margin-top: -4.5em;
		margin-bottom: 4.5em;
	}
	.hr-legend {
		font-size: 1.4em;
	}
	.form-trio-line {
		margin: 2.1875em 0 2.625em;
	}
}
